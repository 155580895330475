#navbar {
  position: fixed;
  width: 100vw;
  height: 94px;
  background-color: #05446A;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  z-index: 100;
  font-family: "Tajawal", sans-serif;
  color: white;
  box-shadow: 0 0 10px rgba(236, 236, 236, 0.2274509804);
}
#navbar .logo {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: clamp(400px, 40vw, 500px);
  margin: 0px 8px;
}
#navbar .logo img {
  height: 95%;
  object-fit: cover;
}
#navbar .logo h1 {
  font-size: clamp(26px, 5vw, 40px);
  margin: 0px 3px;
}
#navbar .buttonWrapper {
  margin: 0px 4px;
}
#navbar .buttonWrapper .switch:has(input:checked) .slider {
  color: rgb(32, 32, 32);
}
#navbar .buttonWrapper .switch {
  position: relative;
  display: inline-block;
  width: 180px;
  height: 54px;
}
#navbar .buttonWrapper .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
#navbar .buttonWrapper .switch input:checked + .slider {
  background-color: #e6e6e6;
}
#navbar .buttonWrapper .switch input + .slider {
  box-shadow: 0 0 2px #ffffff;
}
#navbar .buttonWrapper .switch input:checked + .slider:before {
  -webkit-transform: translateX(125px);
  -ms-transform: translateX(125px);
  transform: translateX(125px);
  background-color: black;
}
#navbar .buttonWrapper .switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(0, 0, 0);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  font-size: 18px;
}
#navbar .buttonWrapper .switch .slider:before {
  position: absolute;
  content: "";
  height: 46px;
  width: 46px;
  left: 5px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}
#navbar .buttonWrapper .switch .slider .text {
  width: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: none;
}


#underConstruction {
    width: clamp(400px, 60vw, 1200px);
    min-height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    font-family: 'Tajawal', sans-serif;
    color: white;
    background-image: linear-gradient(60deg, rgba(44, 0, 148, 0.75), rgba(83, 0, 83, 0.75), rgba(0, 75, 161, 0.75));
    background-size: 600% 600%;
    animation: gradient 12s ease infinite;
    border-radius:  40px;
    padding: 20px 5px;

    &>h1 {
        font-size: clamp(1.8rem, 2vw, 3rem);
        margin-bottom: 2rem;
    }

    .contact {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 1rem;

        .Dev {
            margin: 1rem 0;
        }

        h2 {
            font-size: clamp(1.2rem, 1.5vw, 2rem);
        }

        & a {
            color: white;
            text-decoration: underline;
            font-size: clamp(1.2rem, 1.5vw, 2rem);
            margin: 0.1rem 0;

            &:hover {
                color: rgb(247, 0, 255);
                scale: 1.3;
            }
        }

        
    }
}
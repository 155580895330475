* {
    box-sizing: border-box;
    margin: 0;
    transition: all 0.2s;
    font-family: inherit;
}

#main-div {
    position: relative;
    width: 100%;
    padding-top: 64px;
    min-height: 100vh;
    background-image: url("../images/background.jpg");
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    

    /* background-image: linear-gradient(60deg, rgb(44, 0, 148), rgb(83, 0, 83), rgb(0, 75, 161));
    background-size: 600% 600%;
    animation: gradient 15s ease infinite; */
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

#root {
    position: relative;
}
#navbar {
    position: fixed;
    width: 100vw;
    height: 94px;
    background-color: #05446A;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    z-index: 100;
    font-family: 'Tajawal', sans-serif;
    color: white;
    box-shadow: 0 0 10px #ececec3a;

    .logo {
        height: 100%;
        // font-size: clamp(26px, 5vw, 40px);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        width: clamp(400px, 40vw, 500px);
        margin: 0px 8px;

        img {
            height: 95%;
            object-fit: cover;
        }

        h1 {
            font-size: clamp(26px, 5vw, 40px);
            margin: 0px 3px;
        }
    }


    .buttonWrapper {
        margin: 0px 4px;

        .switch:has(input:checked) .slider{
            color:rgb(32, 32, 32)
        }

        .switch {
            position: relative;
            display: inline-block;
            width: 180px;
            height: 54px;

            input {
                opacity: 0;
                width: 0;
                height: 0;
            }


            input:checked+.slider {
                background-color: #e6e6e6;
            }

            input+.slider {
                box-shadow: 0 0 2px #ffffff;
            }

            input:checked+.slider:before {
                -webkit-transform: translateX(125px);
                -ms-transform: translateX(125px);
                transform: translateX(125px);
                background-color: black;
            }


            .slider {
                position: absolute;
                cursor: pointer;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: rgb(0, 0, 0);
                -webkit-transition: .4s;
                transition: .4s;
                border-radius: 54px;
                display: flex;
                justify-content: center;
                align-items: center;
                user-select: none;
                font-size: 18px;


                &:before {
                    position: absolute;
                    content: "";
                    height: 46px;
                    width: 46px;
                    left: 5px;
                    bottom: 4px;
                    background-color: white;
                    -webkit-transition: .4s;
                    transition: .4s;
                    border-radius: 50%;
                }

                .text {
                    width: 60px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    transition: none;

                }
            }
        }
    }

    // .links {
    //     height: 100%;
    //     display: flex;
    //     flex-direction: row;
    //     gap: 2rem;
    //     align-items: center;
    //     justify-content: space-around;

    //     a {
    //         text-decoration: none;
    //         color: rgb(32, 32, 32);
    //         font-size: 2em;

    //         &:hover {
    //             color: rgb(95, 123, 188)
    //         }
    //     }

    // }
}